import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'mission-app',
    templateUrl: 'mission.component.html',
    styleUrls: ['mission.component.css']
})

export class MissionComponent implements OnInit {
    year;
  constructor() {
        let theDate = new Date();
        this.year = theDate.getFullYear();
        console.log(this.year);
  }
    ngOnInit() { }
}