import { Component, HostListener } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    trigger('fadeIn', [

        // ...
        state('open', style({
          height: '200px',
          opacity: 1,
          backgroundColor: 'yellow'
        })),
        state('closed', style({
          height: '100px',
          opacity: 0.5,
          backgroundColor: 'green'
        })),
        transition('open => closed', [
          animate('1s')
        ]),
        transition('closed => open', [
          animate('0.5s')
        ]),
    ])
  ],
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  faBars = faBars
  title = 'Choose your player:';
  fieldpreppage = null;
  constructor(private router: Router ) {
    router.events.subscribe((url:any) => {
      console.log(url)
      console.log(url.url);
      if (url.url != undefined) {
        this.fieldpreppage = url.url;
      }
      //console.log(this.fieldpreppage);
    });

  }


  @HostListener('mouseenter')
  onMouseEnter() {
    //this.highlight('yellow');
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    //this.highlight(null);
  }

}
