import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fieldprep',
  templateUrl: 'field-prep.component.html'
})

export class FieldPrepComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
