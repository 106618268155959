import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { GamesComponent } from './games/games.component';
import { MissionComponent } from './mission/mission.component';
import { FieldPrepComponent } from './fieldprep/field-prep.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'nextgame', component: GamesComponent},
  { path: 'mission', component: MissionComponent},
  { path: 'fieldprep', component: FieldPrepComponent},
  { path: '**', component: HomeComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
