import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from '../services/main.service';


@Component({
    selector: 'games-app',
    templateUrl: 'games.component.html',
    styleUrls: ['games.component.css']
})

export class GamesComponent implements OnInit {
    year;
    profileJoe;
    profileAlex;
    gamesJoe;
    gamesAlex;
    yearId;
    activeyear;

  constructor(private http: HttpClient, private main: MainService) {
        let theDate = new Date();
        this.year = theDate.getFullYear();
        console.log(this.year);
  }

    ngOnInit() { 
        this.main.getMainPage("activeyear").subscribe(d0 => {
            const me = d0;
            this.activeyear = d0;
            this.yearId = me[0].id;
            const currentTime = new Date();
    
            // returns the year (four digits)
            const year = currentTime.getFullYear().toString();
            this.selectedYear(this.yearId);
    
        });

    }

    selectedYear(yearId) {
    
        this.profileJoe = null;
    
        if (yearId == null || yearId === undefined || yearId === "") {
          this.profileJoe = null;
          return;
        }
    
        this.yearId = yearId;
  
        console.log(yearId);
    
        this.main.getMainPage("profile/" + yearId + "/1").subscribe(data => {
          this.profileJoe  = data;
          console.log(data);
        });
  
  
        this.main.getMainPage("games/GetNextGame/" + yearId + "/1").subscribe(data => {
          if (data.length == 0) {
            this.gamesJoe = null;
          } else {
            this.gamesJoe = data;
          }
          console.log(data);
        });
  
        this.main.getMainPage("profile/" + yearId + "/2").subscribe(data => {
          this.profileAlex = data;
          console.log(data);
        });
    
        this.main.getMainPage("games/GetNextGame/" + yearId + "/2").subscribe(data => {
          
          if (data.length == 0) {
            this.gamesAlex = null;
          } else {
            this.gamesAlex = data;
          }
          console.log(data);
        });
        // this.main.getMainPage("getteamstats/" + yearId).subscribe(data => {
        //   this.team = data;
        // });
    
        // this.main.getMainPage("getplayoffteamstats/" + yearId).subscribe(data => {
        //   console.log(data);
        //   if (data.length == 0) {
        //     this.playoffs = null;
        //   } else {
        //     this.playoffs = data;
        //   }
        // });
      }
}