import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from '../services/main.service';

@Component({
    selector: 'home',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.css']
})

export class HomeComponent implements OnInit {
    year;
    profileJoe;
    profileAlex;
    statsJoe;
    statsAlex;
    yearId;
    activeyear;

    yearsCount;
    yearsCountaj;
    negativeClicks;
    negativeClicksaj;
    postiveClicks;
    postiveClicksaj;
    years;
    position;
    positionaj;

  constructor(private http: HttpClient, private main: MainService) {
        let theDate = new Date();
        this.year = theDate.getFullYear();
        console.log(this.year);
  }

    ngOnInit() { 
      this.main.getMainPage("activeyear").subscribe(d0 => {
        const me = d0;
        console.log(d0);
        this.activeyear = d0;
        this.yearId = me[0].id;
        const currentTime = new Date();

        // returns the year (four digits)
        const year = currentTime.getFullYear().toString();
        this.selectedYear(this.yearId);
        this.selectedYearaj(this.yearId);
        this.getYearsCount();
        this.getYearsCountaj();
        this.postiveClicks = 0;  
        this.postiveClicksaj = 0;       
      });
    }
    
    getYearsCount() {
      this.main.getMainPage("years").subscribe(y=> {
        this.years = y;
        this.yearsCount = y.length;
        console.log("years count: " + this.yearsCount);

        if (this.yearsCount) {
          //load count for how many negative clicks
          console.log(this.activeyear);
          console.log(this.years);
          
          //need exact for getting from array
          this.position = this.years.map(e => e.id).indexOf(this.activeyear[0].id);

          //for buttons
          let pos1 = this.years.map(e => e.id).indexOf(this.activeyear[0].id) + 1;
          //get what's left for negative
          let pos2 =  pos1 - 1;
          
          let pos3 = this.yearsCount - pos1;
          this.negativeClicks = pos3;
          this.postiveClicks = pos1;
          console.log("negative: " + this.negativeClicks + " positve: " + this.postiveClicks);
          //get what's left for positive

          console.log("Position of Current Year: " + pos1);
          console.log(this.years[this.position]);

        }
      });
    }


    getYearsCountaj() {
      this.main.getMainPage("years").subscribe(y=> {
        this.years = y;
        this.yearsCountaj = y.length;
        console.log("years count: " + this.yearsCountaj);

        if (this.yearsCount) {
          //load count for how many negative clicks
          console.log(this.activeyear);
          console.log(this.years);
          
          //need exact for getting from array
          this.positionaj = this.years.map(e => e.id).indexOf(this.activeyear[0].id);

          //for buttons
          let pos1 = this.years.map(e => e.id).indexOf(this.activeyear[0].id) + 1;
          //get what's left for negative
          let pos2 =  pos1 - 1;
          
          let pos3 = this.yearsCountaj - pos1;
          this.negativeClicksaj = pos3;
          this.postiveClicksaj = pos1;
          console.log("negative: " + this.negativeClicksaj + " positve: " + this.postiveClicksaj);
          //get what's left for positive

          console.log("Position of Current Year: " + pos1);
          console.log(this.years[this.positionaj]);

        }
      });
    }

    negativeClick() {
      if (this.negativeClicks >= 0) {
        this.negativeClicks = this.negativeClicks - 1;
        this.postiveClicks = this.postiveClicks + 1;  
        this.position = this.position + 1;
        console.log(this.years[this.position]);  
        this.selectedYear(this.years[this.position].id);   
      }     
      console.log("negative: " + this.negativeClicks + " positve: " + this.postiveClicks);
    }

    positiveClick() {
      if (this.postiveClicks <= this.yearsCount) {
        if (this.postiveClicks >= 1) {
          this.negativeClicks = this.negativeClicks + 1;
          this.postiveClicks = this.postiveClicks - 1;
          this.position = this.position - 1;
          console.log(this.years[this.position]);   
          this.selectedYear(this.years[this.position].id);
        } else {
          console.log("what to do");
          this.postiveClicks = 0;
          this.negativeClicks = this.yearsCount;
          this.selectedYear(this.years[this.position].id);
        }
      }
      console.log("negative: " + this.negativeClicks + " positve: " + this.postiveClicks);
    }

    positiveClickaj() {
      if (this.postiveClicksaj <= this.yearsCountaj) {
        if (this.postiveClicksaj >= 1) {
          this.negativeClicksaj = this.negativeClicksaj + 1;
          this.postiveClicksaj = this.postiveClicksaj - 1;
          this.positionaj = this.positionaj - 1;
          console.log(this.years[this.positionaj]);   
          this.selectedYearaj(this.years[this.positionaj].id);
        } else {
          console.log("what to do");
          this.postiveClicksaj = 0;
          this.negativeClicksaj = this.yearsCountaj;
          this.selectedYearaj(this.years[this.positionaj].id);
        }
      }
      console.log("negative: " + this.negativeClicksaj + " positve: " + this.postiveClicksaj);
    }
    negativeClickaj() {
      if (this.negativeClicksaj >= 0) {
        this.negativeClicksaj = this.negativeClicksaj - 1;
        this.postiveClicksaj = this.postiveClicksaj + 1;  
        this.positionaj = this.positionaj + 1;
        console.log(this.years[this.positionaj]);  
        this.selectedYearaj(this.years[this.positionaj].id);   
      }     
      console.log("negative: " + this.negativeClicksaj + " positve: " + this.postiveClicksaj);
    }

    selectedYear(yearId) {
    
      this.profileJoe = null;
  
      if (yearId == null || yearId === undefined || yearId === "") {
        this.profileJoe = null;
        return;
      }
  
      this.yearId = yearId;

      console.log(yearId);
  
      this.main.getMainPage("profile/" + yearId + "/1").subscribe(data => {
        this.profileJoe  = data;
        console.log(data);
      });

      this.main.getMainPage("playerstats/" + yearId + "/1").subscribe(data => {
        this.statsJoe  = data;
        console.log(data);
      });

      // this.main.getMainPage("profile/" + yearId + "/2").subscribe(data => {
      //   this.profileAlex  = data;
      //   console.log(data);
      // });
  
      // this.main.getMainPage("playerstats/" + yearId + "/2").subscribe(data => {
        
      //   if (data.length == 0) {
      //     this.statsAlex = null;
      //   } else {
      //     this.statsAlex = data;
      //   }
      //   console.log(data);
      // });
    }

    selectedYearaj(yearId) {  
  
      if (yearId == null || yearId === undefined || yearId === "") {
        this.profileAlex = null;
        return;
      }
  
      this.yearId = yearId;

      console.log(yearId);       

      this.main.getMainPage("profile/" + yearId + "/2").subscribe(data => {
        this.profileAlex  = data;
        console.log(data);
      });
  
      this.main.getMainPage("playerstats/" + yearId + "/2").subscribe(data => {
        
        if (data.length == 0) {
          this.statsAlex = null;
        } else {
          this.statsAlex = data;
        }
        console.log(data);
      });
    }
}