import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'nav-app',
    templateUrl: 'nav.component.html'
})

export class NavComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}